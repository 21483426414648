const addNewCardToggler = document.querySelector("#payment-add-new-card");
if (addNewCardToggler) {
    addNewCardToggler.addEventListener("click", ()=>{
        const form = addNewCardToggler.closest("form");
        const hiddenElements = form.querySelectorAll(":scope > .hidden");
        form.querySelector(".row-to-hide").classList.add('hidden');
        hiddenElements.forEach(element => {
            element.classList.remove("hidden");
        })
        return;
    })
}